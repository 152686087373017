<template>
  <div class="ClientScreen">
    <div id="map" style="width: 100%; height: 100%;"></div>
    <div class="left-container" v-if="clientMsg == ''">
      <transition name="show">
        <div class="left-main" v-show="leftShow">
          <div class="left-header">
            <a-icon type="tag" class="ant-menu-item-selected icon" />
            <span>客户筛选</span>
          </div>
          <div class="content">
            <div class="row">
              <span>所属中心</span>
              <div>
                <a-tree-select
                  style="width: 100%;"
                  :treeData="treeData"
                  treeCheckable
                  @change="_selectCentre"
                  :showCheckedStrategy="SHOW_PARENT"
                  searchPlaceholder="请选择"
                />
              </div>
            </div>

            <div class="row">
              <span>所属来源</span>
              <div>
                <a-select
                  style="width: 110px;"
                  v-model="FSource"
                  placeholder="类型"
                  @change="_ChangeKind"
                >
                  <a-select-option disabled selected hidden value=""
                    >请选择</a-select-option
                  >
                  <a-select-option
                    v-for="(item, index) in sourceData"
                    :key="index"
                    >{{ item.FieldName }}</a-select-option
                  >
                </a-select>
                <a-select
                  style="width: 120px; margin-left: 5px;"
                  v-model="Source"
                  placeholder="具体来源"
                >
                  <a-select-option disabled selected hidden value=""
                    >请选择</a-select-option
                  >
                  <a-select-option
                    :value="item.FieldName"
                    v-for="(item, i) in secondList"
                    >{{ item.FieldName }}</a-select-option
                  >
                </a-select>
              </div>
            </div>

            <div class="row">
              <span>显示状态</span>
              <div>
                <a-radio-group v-model="shouStatus">
                  <a-radio :value="1">只看我的</a-radio>
                  <a-radio :value="0">显示全部</a-radio>
                </a-radio-group>
              </div>
            </div>

            <div class="row">
              <span>显示竞品</span>
              <div>
                <a-radio-group v-model="isOpponent">
                  <a-radio :value="1">是</a-radio>
                  <a-radio :value="0">否</a-radio>
                </a-radio-group>
              </div>
            </div>

            <div class="row">
              <span>年龄范围</span>
              <div class="flex">
                <a-select
                  defaultValue="无"
                  style="flex: 1;"
                  v-model="age_1"
                  @change="_selectAge"
                >
                  <a-select-option
                    :value="item"
                    v-for="(item, index) in ageList"
                    >{{ item }}</a-select-option
                  >
                </a-select>
                <span style="width: 20px; text-align: center;">~</span>
                <a-select defaultValue="无" style="flex: 1;" v-model="age_2">
                  <a-select-option
                    :value="item"
                    v-for="(item, index) in ageList"
                    :disabled="age_1 > item"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </div>
            </div>

            <div class="row">
              <span style="width: 110px;">
                <a-select style="width: 95%;" v-model="dateType">
                  <a-select-option
                    :value="item"
                    v-for="(item, i) in dateTypeList"
                    >{{ item }}</a-select-option
                  >
                </a-select>
              </span>
              <div>
                <a-range-picker
                  style="width: 100%;"
                  :placeholder="['开始时间', '结束时间']"
                  @change="_selectDate"
                />
              </div>
            </div>

            <div class="row">
              <span>客户状态</span>
              <div>
                <a-select
                  mode="multiple"
                  style="width: 100%;"
                  @change="_selectStatus"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option
                    :value="item.FieldValue"
                    v-for="(item, index) in StatusList"
                    >{{ item.FieldName }}</a-select-option
                  >
                </a-select>
              </div>
            </div>
            <!--<div class="row">-->
            <!--<span>区域筛选</span>-->
            <!--<div style="display: flex;width: 100%">-->
            <!--<a-select defaultValue="请选择" style="flex: 1;margin-right: 15px;" @change="proChange">-->
            <!--<a-select-option v-for="item in province" :value="item.Id">{{item.Name}}</a-select-option>-->

            <!--</a-select>-->

            <!--<a-select defaultValue="请选择" style="flex: 1;margin-right: 15px;" @change="cityChange">-->
            <!--<a-select-option v-if="CityList" v-for="item in CityList" :value="item.Id">{{item.Name}}</a-select-option>-->

            <!--</a-select>-->
            <!--<a-select defaultValue="请选择" v-model="Region" style="flex: 1;" >-->
            <!--<a-select-option v-if="area" v-for="item in area" :value="item.Id">{{item.Name}}</a-select-option>-->
            <!---->
            <!--</a-select>-->
            <!--</div>-->
            <!--</div>-->

            <!--<div class="left-header" style="padding-top: 20px;">-->
            <!--<img src="" alt=""> <span>客户标签筛选</span>-->
            <!--</div>-->
            <!--<div class="row">-->
            <!--<span>标签筛选</span>-->
            <!--<div>-->
            <!--<a-tree-select style="width: 100%" :treeData="treeData" treeCheckable searchPlaceholder='Please select'/>-->
            <!--</div>-->
            <!--</div>-->

            <div class="row" style="text-align: right; display: block;">
              <a-button type="primary" @click="_info">开始查询</a-button>
            </div>
          </div>
        </div>
      </transition>
      <div
        class="cut-btn"
        @click="_cutShow"
        @mouseover.stop="_hintShow"
        @mouseout.stop="_hintShow"
      >
        <a-icon
          :type="leftShow ? 'caret-left' : 'caret-right'"
          style="color: #8a8a8a;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BMap from 'BMap'
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
var userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'ClientScreen',
  components: {},
  data() {
    return {
      list: [],
      visible: false,
      leftShow: true,
      hintMsg: false,
      SHOW_PARENT: SHOW_PARENT,
      treeData: [],
      StatusList: [],
      schoollist: [],
      competitor: [],
      allSchoolList: [],
      ageList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],

      page: 1,
      SchoolId: '',
      StartTime: '',
      EndTime: '',
      Status: '',
      age_1: 0,
      age_2: 9,
      Actid: 0,
      province: '',
      RegionType: 1, //1是省2是市3是区
      CityList: '',
      area: '',
      Province: '',
      City: '',
      Region: '',
      clientMsg: '',
      dateTypeList: [
        '创建时间',
        '新转入',
        '转入时间',
        '转出时间',
        '成交时间',
        '体验时间',
      ],
      dateType: '创建时间',
      sourceData: '',
      secondList: '',
      FSource: '',
      isOpponent: 1,
      shouStatus: 1,
      FSourceId: '',
      Source: '',
    }
  },
  created() {
    this.regionList()
    if (this.$route.query.x) {
      this.clientMsg = {
        y: this.$route.query.y,
        x: this.$route.query.x,
        name: this.$route.query.name,
      }
    }
    this.$axios.post(8000014, {}, (res) => {
      if (res.data.code == 1) {
        this.sourceData = res.data.data
        this.sourceData.map((r2) => {})
      }
    })
    //this.clientMsg = {y:30.276905050438,x:120.48726062897,name: '梁景翔',id: '123456'}
  },
  mounted() {
    this.$axios.get(5836120, { userId: userInfo.uid }, (res) => {
      if (res.data.code == 1) {
        this.treeData = res.data.data.schoollist
        this.StatusList = res.data.data.statuslist
      }
    })
    if (this.clientMsg != '') {
      this.$axios.get(9957, {}, (res) => {
        if (res.data.code == 1) {
          this.allSchoolList = res.data.data
          this._skimCreareMap()
          this.$message.destroy()
        } else {
          this.$message.destroy()
        }
      })
    } else {
      this.createMap()
    }
  },
  methods: {
    _ChangeKind(i) {
      let arr = this.sourceData[i].children
      this.FSourceId = this.sourceData[i].Id
      this.secondList = arr
      this.Source = ''
    },
    regionList() {
      let self = this
      this.$axios.post(
        5836121,
        {
          Actid: self.Actid,
        },
        (res) => {
          if (self.RegionType == 1) {
            self.province = res.data.message
          } else if (self.RegionType == 2) {
            self.CityList = res.data.message
          } else if (self.RegionType == 3) {
            self.area = res.data.message
          }
        }
      )
    },
    proChange(value) {
      if (value) {
        this.RegionType = 2
        this.Actid = value
        this.Province = value
        this.regionList()
      }
    },
    cityChange(value) {
      if (value) {
        this.RegionType = 3
        this.Actid = value
        this.City = value
        this.regionList()
      }
    },
    _info() {
      let self = this
      if (this.SchoolId == '') {
        this.$message.error('中心必选哦')
        return false
      }
      let data = {
        page: this.page,
        SchoolId: this.SchoolId,
        StartTime: this.StartTime,
        EndTime: this.EndTime,
        Status: this.Status,
        Age: this.age_1 + ',' + this.age_2,
        Province: this.Province,
        City: this.City,
        Region: this.Region,
        Fsource: this.FSourceId,
        Source: this.Source,
        IsShowCompetitor: this.isOpponent,
        JustShowMine: this.shouStatus,
      }
      this.$message.loading('查询中...', 0)
      this.$axios.get(5836119, data, (res) => {
        if (res.data.code == 1) {
          self.list = res.data.data.list
          self.schoollist = res.data.data.schoollist
          self.competitor = res.data.data.competitor
          self.createMap()
          if (res.data.data.list.length >= 10) {
            self.page += 1
          }
          self.$message.destroy()
        } else if (res.data.code == 0) {
          self.list = []
          self.schoollist = []
          self.competitor = []
          self.$message.destroy()
          this.$message.error('没有查看到数据!!')
        }
      })
    },
    _selectAge(value) {
      if (value > this.age_2) {
        this.age_2 = value
      }
    },
    _selectStatus(value) {
      this.Status = value.join(',')
    },
    _selectDate(value) {
      if (value.length == 0) {
        this.StartTime = ''
        this.EndTime = ''
      } else {
        this.StartTime = this.__moment__(value[0]).format('YYYY-MM-DD')
        this.EndTime = this.__moment__(value[1]).format('YYYY-MM-DD')
      }
    },
    _selectCentre(value) {
      this.SchoolId = value.join(',')
    },
    _hintShow() {
      this.hintMsg = !this.hintMsg
    },
    _cutShow() {
      this.leftShow = !this.leftShow
      this.hintMsg = false
    },
    onClose() {
      this.visible = !this.visible
    },
    _addList(data, centreList) {
      let self = this
      var map = new BMap.Map('map', {}) // 创建Map实例
      map.centerAndZoom(new BMap.Point(centreList[0].y, centreList[0].x), 10) // 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom()
      let icon = function (iconWidth, iconHeight, iconUrl) {
        let size = new BMap.Size(iconWidth, iconHeight)
        return new BMap.Icon(iconUrl, size)
      }
      //启用滚轮放大缩小
      if (document.createElement('canvas').getContext) {
        // 判断当前浏览器是否支持绘制海量点
        //添加竞品点
        if (self.competitor.length != 0) {
          for (let i = 0; i < self.competitor.length; i++) {
            let marker = new BMap.Marker(
              new BMap.Point(self.competitor[i].y, self.competitor[i].x),
              {
                icon: icon(
                  20,
                  20,
                  self.competitor[i].img + '?imageView2/1/w/20/h/20'
                ),
              }
            ) // 创建标注
            // <div style="font-size:12px;margin-top:10px;">电话号码: ${mapPoints[i].Phone}</div>
            let content = `<div>
                                            <div>${
                                              self.competitor[i].name
                                            }</div>
                                            <div style="font-size:12px;margin-top:10px;">${
                                              self.competitor[i].Phone
                                            }</div>
                                            <div style="font-size:12px;">${self.competitor[
                                              i
                                            ].Addr.slice(
                                              0,
                                              18
                                            )} <br/> <span style="margin-left:30px;">${self.competitor[
              i
            ].Addr.substring(18)}</span></div>
                                       </div>
                                        `
            let opts = {
              maxWidth: 220,
            }
            let infoWindow = new BMap.InfoWindow(content, opts)
            marker.addEventListener('mouseover', function (e) {
              let p = e.target
              let scaleVal = map.getZoom()
              let point = new BMap.Point(
                p.getPosition().lng,
                p.getPosition().lat + 4.8 / Math.pow(2, scaleVal - 3)
              )
              // 创建信息窗口对象
              map.openInfoWindow(infoWindow, point)
            })
            map.addOverlay(marker)
          }
        }
        //添加中心点
        for (let i = 0; i < centreList.length; i++) {
          let marker = new BMap.Marker(
            new BMap.Point(centreList[i].y, centreList[i].x),
            {
              icon: icon(
                40,
                40,
                'https://smart-resource.sikegroup.com/saas/image/mapIcon.png'
              ),
            }
          ) // 创建标注
          // <div style="font-size:12px;margin-top:10px;">电话号码: ${mapPoints[i].Phone}</div>
          let content = `<div>
                                            <div>${centreList[i].name}</div>
                                            <div style="font-size:12px;margin-top:10px;">电话号码: ${
                                              centreList[i].Phone
                                            }</div>
                                            <div style="font-size:12px;">地址:  ${centreList[
                                              i
                                            ].Addr.slice(
                                              0,
                                              18
                                            )} <br/> <span style="margin-left:30px;">${centreList[
            i
          ].Addr.substring(18)}</span></div>
                                       </div>
                                        `
          let opts = {
            maxWidth: 220,
          }
          let infoWindow = new BMap.InfoWindow(content, opts)
          marker.addEventListener('mouseover', function (e) {
            let p = e.target
            let scaleVal = map.getZoom()
            let point = new BMap.Point(
              p.getPosition().lng,
              p.getPosition().lat + 4.8 / Math.pow(2, scaleVal - 3)
            )
            // 创建信息窗口对象
            map.openInfoWindow(infoWindow, point)
          })
          map.addOverlay(marker)
        }

        var points = [] // 添加海量点数据
        var points1 = []
        var points4 = []
        for (var i = 0; i < data.length; i++) {
          var point = new BMap.Point(data[i].y, data[i].x)
          ;(point.id = data[i].id),
            (point.name = data[i].name),
            (point.FieldName = data[i]['FieldName']),
            (point.UserName = data[i]['UserName'])
          point.HomeAddr = data[i]['HomeAddr']
          points.push(point)
          if (data[i]['Status'] == 1) {
            var point1 = new BMap.Point(data[i].y, data[i].x)
            points1.push(point1)
          }
          if (data[i]['Status'] == 4) {
            var point4 = new BMap.Point(data[i].y, data[i].x)
            points4.push(point4)
          }
        }

        var options = {
          size: 40,
          shape: BMAP_POINT_SHAPE_STAR,
          color: '#faad14',
        }
        //BMAP_POINT_SHAPE_WATERDROP
        var pointCollection = new BMap.PointCollection(points, options) // 初始化PointCollection
        pointCollection.addEventListener('click', function (e) {
          window.open(
            'https://ims.qitianzhen.cn/index.php?m=Kms&c=Student&a=edit&UserId=' +
              e.point.id
          )
        })
        pointCollection.addEventListener('mouseover', function (e) {
          var point = new BMap.Point(e.point.lng, e.point.lat)

          var openinfoData = `<div>
                                            <div>姓名:${e.point.name}</div>
                                            <div style="font-size:12px;">电话号码: ${e.point.UserName}</div>
                                            <div style="font-size:12px;">会员状态:  ${e.point.FieldName} </div>
                                            <div style="font-size:12px;">家庭住址:  ${e.point.HomeAddr} </div>
                                       </div>
                                        `
          var opts = {
            width: 100, // 信息窗口宽度
            height: 100, // 信息窗口高度
            title: '会员信息', // 信息窗口标题
            enableMessage: false, //设置允许信息窗发送短息
          }
          var infowindow = new BMap.InfoWindow(openinfoData, opts)
          map.openInfoWindow(infowindow, point)
        })
        pointCollection.addEventListener('mouseout', function () {
          this.closeInfoWindow()
        })
        //成交客户
        map.addOverlay(pointCollection) // 添加Overlay
        var options1 = {
          size: 40,
          shape: BMAP_POINT_SHAPE_STAR,
          color: '#52c41a',
        }
        var pointCollection1 = new BMap.PointCollection(points1, options1)
        map.addOverlay(pointCollection1)
        // map.setMapStyleV2({
        //     styleId: '3d71dc5a4ce6222d3396801dee06622d'
        // });

        //体验客户
        var options4 = {
          size: 40,
          shape: BMAP_POINT_SHAPE_STAR,
          color: '#722ed1',
        }
        var pointCollection4 = new BMap.PointCollection(points4, options4)
        map.addOverlay(pointCollection4)
      } else {
        alert('请在chrome、safari、IE8+以上浏览器查看本页面')
      }
    },
    createMap() {
      let self = this
      let map = new BMap.Map('map')
      map.centerAndZoom(new BMap.Point(116.331398, 39.897445), 6) //// 初始化地图,设置中心点坐标和地图级别
      let geolocation = new BMap.Geolocation()
      let gc = new BMap.Geocoder() //创建地理编码器
      // 开启SDK辅助定位
      geolocation.enableSDKLocation()
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          if (self.list.length != 0) {
            self._addList(self.list, self.schoollist)
          }
          map.panTo(r.point)
          var pt = r.point
          map.panTo(pt) //移动地图中心点
          gc.getLocation(pt, function (rs) {
            var addComp = rs.addressComponents
          })
        } else {
          alert('failed' + this.getStatus())
        }
      })
      //开启鼠标滚轮缩放
      map.enableScrollWheelZoom(true)
    },
    _skimCreareMap() {
      let self = this
      let map = new BMap.Map('map')
      map.centerAndZoom(new BMap.Point(this.clientMsg.x, this.clientMsg.y), 12) //// 初始化地图,设置中心点坐标和地图级别
      //添加标注图标
      let icon = function (iconWidth, iconHeight, iconUrl) {
        let size = new BMap.Size(iconWidth, iconHeight)
        return new BMap.Icon(iconUrl, size)
      }
      let userMarker = new BMap.Marker(
        new BMap.Point(this.clientMsg.x, this.clientMsg.y)
      ) // 创建标注
      userMarker.addEventListener('mouseover', function (e) {
        let content = `<div><div>客户名称：${self.clientMsg.name}</div></div>`
        let infoWindow = new BMap.InfoWindow(content, { maxWidth: 220 })
        let p = e.target
        let scaleVal = map.getZoom()
        let point = new BMap.Point(
          p.getPosition().lng,
          p.getPosition().lat + 4.8 / Math.pow(2, scaleVal - 3)
        )
        // 创建信息窗口对象
        map.openInfoWindow(infoWindow, point)
      })
      map.addOverlay(userMarker)
      for (let i = 0; i < self.allSchoolList.length; i++) {
        let point = self.allSchoolList[i].MapPoint.split(',')
        let marker = new BMap.Marker(new BMap.Point(point[0], point[1]), {
          icon: icon(
            50,
            50,
            'https://smart-resource.sikegroup.com/saas/image/mapIcon.png'
          ),
        }) // 创建标注
        let content = `<div>
                                        <div>${
                                          self.allSchoolList[i].OrgName
                                        }</div>
                                        <div style="font-size:12px;margin-top:10px;">电话号码: ${
                                          self.allSchoolList[i].Phone
                                        }</div>
                                        <div style="font-size:12px;">地址:  ${self.allSchoolList[
                                          i
                                        ].Addr.slice(
                                          0,
                                          18
                                        )} <br/> <span style="margin-left:30px;">${self.allSchoolList[
          i
        ].Addr.substring(18)}</span></div>
                                   </div>
                                    `
        let opts = {
          maxWidth: 220,
        }
        let infoWindow = new BMap.InfoWindow(content, opts)
        marker.addEventListener('mouseover', function (e) {
          let p = e.target
          let scaleVal = map.getZoom()
          let point = new BMap.Point(
            p.getPosition().lng,
            p.getPosition().lat + 4.8 / Math.pow(2, scaleVal - 3)
          )
          // 创建信息窗口对象
          map.openInfoWindow(infoWindow, point)
        })
        map.addOverlay(marker)
      }
      map.enableScrollWheelZoom(true)
    },
  },
}
</script>

<style scoped lang="less">
.ClientScreen {
  height: 100%;
  position: relative;
  .left-container {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    height: 95%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06),
      0 1px 4px rgba(0, 0, 0, 0.08);
  }
  .left-main {
    max-width: 350px;
    height: 100%;
    padding: 20px 15px;
    padding-top: 10px;
    border-radius: 2px;
    .left-header {
      padding-bottom: 8px;
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
      span {
        font-size: 16px;
        color: #666;
      }
      .icon {
        margin-right: 7px;
        font-size: 18px;
        vertical-align: middle;
      }
    }
  }
  .add-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
  }
  .cut-btn {
    position: absolute;
    top: 20px;
    right: -20px;
    width: 20px;
    height: 40px;
    line-height: 38px;
    background: #fff;
    border: 1px solid #e4e5e6;
    border-left: none;
    cursor: pointer;
    .hint-msg {
      position: absolute;
      top: 50%;
      right: -85px;
      transform: translateY(-50%);
      background: #000;
      opacity: 0.7;
      padding: 4px 8px;
      white-space: nowrap;
      font-size: 10px;
      line-height: 1.6;
      color: #fff;
      border-radius: 2px;
      .san {
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        border-right: 5px solid #000;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }
    }
  }
  .alert-top {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 8px 15px;
    padding-left: 10px;
    background: #fff;
    border-radius: 4px;
    div {
      margin-bottom: 5px;
    }
    div:last-child {
      margin-bottom: 0;
    }
    i {
      margin-right: 8px;
    }
    span {
      color: #848484;
    }
  }
  .row {
    padding-right: 10px;
    span {
      display: inline-block;
      width: 70px;
      white-space: nowrap;
      color: #666;
      sup {
        color: #ff4646;
      }
    }
    .suffix {
      display: inline-block;
      width: 12px;
      font-size: 12px;
    }
    > div {
      flex: 1;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .list-title {
      font-size: 18px;
      /*font-weight:bold;*/
      color: rgba(102, 102, 102, 1);
    }
  }
}
.show-enter-active {
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}
.show-leave-active {
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}
.show-enter,
.show-leave-to {
  width: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
</style>
